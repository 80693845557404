<app-dialog dialogType="primary">
  <div dialog-title class="fw-500 ta-center fcolor-white row justify-content-between">
    <span class="title">{{ title }}</span>
    <span>{{ startDate | date : "MM/dd/yy" }} - {{ endDate | date : "MM/dd/yy" }}</span>
  </div>
  <div dialog-content class="fs-14">
    <ng-container *ngIf="!appComponent.isBusy">
      <p *ngIf="isCurrent" class="alert alert-info">
        <ng-container *ngIf="asOfDate">Class data last updated as of {{ asOfDate | date }}</ng-container>
        <ng-container *ngIf="!asOfDate">
          There is no class data available for this student. Please check back later.
        </ng-container>
      </p>
      <p *ngIf="!isCurrent" class="alert alert-warning">
        <ng-container *ngIf="asOfDate">
          This class data is a
          <b>snapshot</b>
          of the student's classes and grades as of {{ asOfDate | date }}.
          <br />
          If you would like to see current class data for this student, please adjust your date range on the main page.
        </ng-container>
        <ng-container *ngIf="!asOfDate">
          There is no class data available for this student for the selected date range.
          <br />
          Please adjust your date range on the main page and try again.
        </ng-container>
      </p>
    </ng-container>

    <table mat-table [dataSource]="dataSource" matSort class="w-100per">
      <ng-container matColumnDef="className">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="className">Class</th>
        <td mat-cell *matCellDef="let row" class="pr-35">{{ row.className }}</td>
      </ng-container>

      <ng-container matColumnDef="liveLessonPoints">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="liveLessonPoints border">Live Lessons</th>
        <td mat-cell *matCellDef="let row" class="pb-10 pt-10 pr-35 border text-nowrap">
          {{ row.liveLessonPoints }} out of {{ row.liveLessonsOffered }}
        </td>
      </ng-container>

      <ng-container matColumnDef="onlineHoursSpentThisWeek">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="onlineHoursSpentThisWeek"
          matTooltip="Online time spent during selected week">
          Time Spent
        </th>
        <td mat-cell *matCellDef="let row" class="pb-10 pt-10 pr-35 text-nowrap">
          {{ Utility.toTimeFormat(row.onlineHoursSpentThisWeek * 3600.0) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="totalSecondsSpentOnline">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="totalSecondsSpentOnline border"
          matTooltip="Total online time spent">
          Time Spent
        </th>
        <td mat-cell *matCellDef="let row" class="pb-10 pt-10 pr-35 border text-nowrap">
          {{ Utility.toTimeFormat(row.totalSecondsSpentOnline) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="score">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="score">Score</th>
        <td mat-cell *matCellDef="let row" class="pb-10 pt-10 pr-35">
          <span matTooltip="{{ row.scoreAchieved }} out of {{ row.scorePossible }}" matTooltipPosition="above">
            {{ row.score }}
          </span>
          <mat-icon
            *ngIf="row.trend === Trend.Upwards"
            color="accent"
            matTooltip="Up {{ row.trendDifference }}%"
            matTooltipPosition="above">
            trending_up
          </mat-icon>
          <mat-icon
            *ngIf="row.trend === Trend.Downwards"
            color="warn"
            matTooltip="Down {{ row.trendDifference }}%"
            matTooltipPosition="above">
            trending_down
          </mat-icon>
          <!-- Currently, not showing a trend icon if flat -->
          <!--<mat-icon *ngIf="row.trend === Trend.Flat">trending_flat</mat-icon>-->
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Enrollment Status</th>
        <td mat-cell *matCellDef="let row">{{ getStatusName(row) }}</td>
      </ng-container>

      <ng-container matColumnDef="assignmentsAssignedDateRange">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tasks Assigned</th>
        <td mat-cell *matCellDef="let row">
          {{ row.assignmentsAssignedDateRange }}
        </td>
      </ng-container>

      <ng-container matColumnDef="assignmentsCompletedInGraceDateRange">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Completed In Grace</th>
        <td mat-cell *matCellDef="let row">
          {{ row.assignmentsCompletedInGraceDateRange }}
        </td>
      </ng-container>

      <ng-container matColumnDef="assignmentsCompletedInGraceUpUntilEndDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="border">Completed In Grace</th>
        <td mat-cell *matCellDef="let row" class="border">
          {{ row.assignmentsCompletedInGraceUpUntilEndDate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="assignmentsCompletedOnTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [matTooltip]="'if done by the assignment date'">
          Completed On Time
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.assignmentsCompletedOnTime }}
        </td>
      </ng-container>

      <ng-container matColumnDef="assignmentsInGracePeriod">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Assignments In Grace Period</th>
        <td mat-cell *matCellDef="let row">{{ row.assignmentsInGracePeriod }}</td>
      </ng-container>

      <ng-container matColumnDef="headerEmptyStart">
        <th mat-header-cell *matHeaderCellDef></th>
      </ng-container>

      <ng-container matColumnDef="headerDateRange">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="dateRangeColumns.length - 1" class="header-cell border">
          {{ isPerformance ? "This Week" : "Selected Date Range" }}
        </th>
      </ng-container>

      <ng-container matColumnDef="headerToEndDate">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="1" class="header-cell border">
          Course Start to
          <br />
          Selected End Date
        </th>
      </ng-container>

      <ng-container matColumnDef="headerAllTime">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="allTimeColumns.length - 1" class="header-cell border">
          All Time
        </th>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="['headerEmptyStart', 'headerDateRange', 'headerToEndDate', 'headerAllTime']; sticky"></tr>
      <tr mat-header-row *matHeaderRowDef="dateRangeColumns.concat(allTimeColumns)"></tr>
      <tr mat-row *matRowDef="let row; columns: dateRangeColumns.concat(allTimeColumns)"></tr>
    </table>

    <div *ngIf="!dataSource.data.length" class="w-100per text-center mt-20 fcolor-gray">
      {{ appComponent.isBusy ? "Loading..." : noResultsMessage }}
    </div>
  </div>
  <div dialog-footer>
    <button mat-button (click)="close()" color="primary">Close</button>
  </div>
</app-dialog>
